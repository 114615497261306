html {
  scroll-behavior: smooth;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: black;
}

a:visited { 
  text-decoration: none;
  color: inherit;
}

.margin {
  margin: 0 12%;
}

.hero-image {
  /* z-index: 1; */
  top: 0;
  position: top;
  /*height: 100%;*/
  object-fit: contain;
  width: 100%;
}

.homepage-container {
  /* height: 100vh; */
  /* overflow: hidden; */
  position: top;
  background: black;
  object-fit: contain;
  display: 'block';
  padding-bottom: 150px;
}

/*
.homepage-container .hero-image {
  width: 100%;
  background-repeat: no-repeat;
  object-fit: contain;
  display: 'block';  
}
*/

.logo-img {
  padding-top: 1em;
  max-width: 13em;
  position: absolute;
}

/* ---- Recent Updates ---- */
.container {
  display: table-row;
}
.tile {
  display: table-cell;
}

/* ---- Acknowledgments ---- */

.acknowledgments {
  background: #fefff2;
  padding: 3em 0em;
  margin: 2em 0em;
}

.sponsor-img {
  height: 5em;
}

/* ---- CONTACT US ---- */

.contact-us {
  margin-top: 3em;
  margin-bottom: 3em;
}

.mail-link {
  text-decoration: none;
  color: #16192c;
}

/* ---- FOOTER ---- */

.fa-facebook-f, .fa-twitter, .fa-vimeo-v, .fa-instagram {
  color: #151d41;
}

.MuiAvatar-root:hover .fa-facebook-f, .MuiAvatar-root:hover .fa-twitter, .MuiAvatar-root:hover .fa-vimeo-v, .MuiAvatar-root:hover .fa-instagram {
  color: white;
}

/* ---- WHATS NEW ---- */

.whats-new {
  background-color: rgba(0, 0, 0, 0.90);
  position: relative;
  /* width: 100%; */
  height: 30%;
  margin-top: "50px";
  display: 'block'
}

.ql-editor{
  height: 568px;
}

.quill-form-explain {
  color: red;
}
.has-error .ql-container {
  border: 1px solid red !important;
}

/* ------ MOBILE ------ */


@media only screen and (max-width: 768px) {
  .homepage-container {
    height: 70vh;
  }
}


@media only screen and (min-width: 1221px) {
  .whats-new {
    margin-top: -10%;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1220px){
  .whats-new {
    margin-top: -17%;
  }
}

@media only screen and (max-width: 768px) {
  .whats-new {
    margin-top: -25%;
  }
}

@media only screen and (max-width: 767px) and (min-width: 500px) {
  .whats-new {
    margin-top: -11%;
  }
}

@media only screen and (max-width: 600px) and (min-width: 500px) {
  .whats-new {
    margin-top: -24%;
  }
}

@media only screen and (max-width: 600px) and (min-width: 550px) {
  .whats-new {
    margin-top: -15%;
  }
}

@media only screen and (max-width: 500px) {
  .whats-new {
    margin-top: -30%;
  }

  .homepage-container {
    height: 70vh;
  }
  .insights-margin {
    margin: 0;
  }
  .mail-link {
    padding-left: 1em;
  }

  .homepage-container .hero-image {
    padding-top: 3em;
    }
}

@media only screen and (min-width: 450px) and (max-width: 499px) {
  .whats-new {
    margin-top: -15%;
  }

  .homepage-container {
    height: 55vh;
  }

  .insights-margin {
    margin: 0;
  }
  .mail-link {
    padding-left: 1em;
  }
}

@media only screen and (min-width: 385px) and (max-width: 449px) {
  .whats-new {
    margin-top: -40%;
  }
}

@media only screen and (max-width: 384px) {
  .homepage-container {
    height: 50vh;
  }
}